ul {
    margin-left: 2em;
}
li {
    margin-top: 0.5em;
}

#privacy p {
    margin-top: 1em;

}

#privacy h2 {
    margin-top: 1em;
}
#privacy h3 {
    margin-top: 1em;
}
#privacy h4 {
    margin-top: 1em;
}

