ol {
    margin-left: 2em;
}
li {
    margin-top: 0.5em;
}

ol li {
    font-size: inherit;
}

#terms > ol > li:first-line {
    font-size: 26px;
}